import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import Navbar from "./NavBar.js";
import PhoneBar from "./PhoneBar.js";
import ContactUs from "./ContactUs.js";
import Footer from "./Footer.js";

const PrivacyPolicy = ({ onLanguageChange }) => {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="privacy-policy mt-24 bg-white w-full "
      id="politica-de-privacidad"
    >
      <Navbar onLanguageChange={onLanguageChange} />
      <PhoneBar />
      <div className="w-4/5 mx-auto py-8">
        <h1 className="text-2xl font-bold mb-4 text-custom-blue">
          {intl.formatMessage({ id: "Policy.Title" })}
        </h1>
        <h1 className="text-xl mb-2 font-bold">
          {intl.formatMessage({ id: "Policy.Acceptance" })}
        </h1>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.AcceptanceText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.Introduction" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.IntroductionText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.InformationWeCollect" })}
        </h2>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({ id: "Policy.InformationYouProvide" })}
        </h3>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.InformationYouProvideText" })}
        </p>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.InformationYouProvideText2" })}
        </p>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({
            id: "Policy.InformationWeCollectAutomatically",
          })}
        </h3>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({
            id: "Policy.InformationWeCollectAutomaticallyText",
          })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.UseOfInformation" })}
        </h2>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({ id: "Policy.WeUseInformationTo" })}
        </h3>
        <ul className="mb-4 leading-relaxed">
          {[
            "Policy.WeUseInformationToList.item1",
            "Policy.WeUseInformationToList.item2",
            "Policy.WeUseInformationToList.item3",
            "Policy.WeUseInformationToList.item4",
            "Policy.WeUseInformationToList.item5",
          ].map((id, index) => (
            <li key={index}>{intl.formatMessage({ id })}</li>
          ))}
        </ul>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.SharingInformation" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.SharingInformationText" })}
        </p>

        <ul className="mb-4 leading-relaxed">
          {[
            "Policy.SharingInformationList.Item1",
            "Policy.SharingInformationList.Item2",
            "Policy.SharingInformationList.Item3",
          ].map((id, index) => (
            <li key={index}>{intl.formatMessage({ id })}</li>
          ))}
        </ul>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.YourChoices" })}
        </h2>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({ id: "Policy.YouHaveTheRightTo" })}
        </h3>
        <ul className="mb-4 leading-relaxed">
          {[
            "Policy.YourChoicesList.Item1",
            "Policy.YourChoicesList.Item2",
            "Policy.YourChoicesList.Item3",
          ].map((id, index) => (
            <li key={index}>{intl.formatMessage({ id })}</li>
          ))}
        </ul>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.YourChoicesText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.InformationSecurity" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.InformationSecurityText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.PolicyChanges" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.PolicyChangesText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.Contact" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.ContactText" })}
        </p>
        <ul className="mb-4 leading-relaxed">
          {[
            "Policy.ContactList.Item1",
            "Policy.ContactList.Item2",
            "Policy.ContactList.Item3",
          ].map((id, index) => (
            <li key={index}>{intl.formatMessage({ id })}</li>
          ))}
        </ul>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.AdditionalInformation" })}
        </h2>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({ id: "Policy.Cookies" })}
        </h3>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.CookiesText" })}
        </p>
        <h3 className="text-lg mb-2">
          {intl.formatMessage({ id: "Policy.GoogleAnalytics" })}
        </h3>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.GoogleAnalyticsText" })}
        </p>
        <h2 className="text-xl mb-2">
          {intl.formatMessage({ id: "Policy.Minors" })}
        </h2>
        <p className="mb-4 leading-relaxed">
          {intl.formatMessage({ id: "Policy.MinorsText" })}
        </p>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
