import React, { useState, useEffect } from "react";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { addWeeks } from "date-fns";

const Statistics = () => {
  const [data, setData] = useState(null);
  const [lastQuote, setLastQuote] = useState(null);
  const [lastContact, setLastContact] = useState(null);

  Chart.register(...registerables);

  useEffect(() => {
    // Obtiene los datos de 'quote' para el gráfico
    fetch(`${process.env.REACT_APP_API_URL}/quotes-per-week`)
      .then((response) => response.json())
      .then((quoteData) => {
        // Obtiene el último 'quote'
        fetch(`${process.env.REACT_APP_API_URL}/quote`)
          .then((response) => response.json())
          .then((allQuotes) => {
            setLastQuote(allQuotes[allQuotes.length - 1]);

            // Obtiene los datos de 'contact' para el gráfico
            fetch(`${process.env.REACT_APP_API_URL}/contacts-per-week`)
              .then((response) => response.json())
              .then((contactData) => {
                // Obtiene el último 'contact'
                fetch(`${process.env.REACT_APP_API_URL}/contact`)
                  .then((response) => response.json())
                  .then((allContacts) => {
                    setLastContact(allContacts[allContacts.length - 1]);

                    setData({
                      labels: quoteData
                        .filter((item) => item._id.year >= 2024)
                        .map((item) => {
                          const date = addWeeks(
                            new Date(item._id.year, 0, 1),
                            item._id.week
                          );
                          return date.toISOString().split("T")[0];
                        }),
                      datasets: [
                        {
                          label: "Quotes",
                          data: quoteData
                            .filter((item) => item._id.year >= 2024)
                            .map((item) => item.count),
                          fill: false,
                          backgroundColor: "rgb(255, 99, 132)",
                          borderColor: "rgba(255, 99, 132, 0.2)",
                        },
                        {
                          label: "Contacts",
                          data: contactData
                            .filter((item) => item._id.year >= 2024)
                            .map((item) => item.count),
                          fill: false,
                          backgroundColor: "rgb(54, 162, 235)",
                          borderColor: "rgba(54, 162, 235, 0.2)",
                        },
                      ],
                    });
                  })
                  .catch((error) => {
                    console.error("Error fetching all contacts:", error);
                  });
              })
              .catch((error) => {
                console.error("Error fetching contact data for chart:", error);
              });
          })
          .catch((error) => {
            console.error("Error fetching all quotes:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching quote data for chart:", error);
      });
  }, []);

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "week",
        },
        displayFormats: {
          week: "MMM D",
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  
    return (
      <div className="w-full md:w-4/5 bg-white p-4 flex flex-col justify-center items-center mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-custom-blue">Estadísticas</h1>
        {data && (
          <div className="h-2/3 w-full mb-8 p-8 bg-gray-100 rounded-lg shadow flex items-center justify-center">
            {data && <Line data={data} options={options} />}
          </div>
        )}
        <div className="flex flex-col md:flex-row justify-between">
          {lastQuote && (
            <div className="mb-4 p-4 bg-gray-100 rounded shadow flex-1 mr-2">
              <h2 className="text-xl font-bold mb-2  text-custom-blue">
                Última Cotización
              </h2>
              <p className="mb-1">
                <span className="font-semibold">Nombre:</span>{" "}
                {lastQuote.nombreCompleto}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Correo:</span>{" "}
                {lastQuote.correoElectronico}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Teléfono:</span>{" "}
                {lastQuote.numeroTelefono}
              </p>
            </div>
          )}
          {lastContact && (
            <div className="mb-4 p-4 bg-gray-100 rounded shadow flex-1 ml-2">
              <h2 className="text-xl font-bold mb-2 text-custom-blue">
                Último Contacto
              </h2>
              <p className="mb-1">
                <span className="font-semibold">Nombre:</span>{" "}
                {lastContact.nombreCompleto}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Correo:</span>{" "}
                {lastContact.correoElectronico}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Teléfono:</span>{" "}
                {lastContact.numeroTelefono}
              </p>
            </div>
          )}
        </div>
      </div>
    );};

export default Statistics;
