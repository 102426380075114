import React, { useEffect, useState } from "react";
import ContactDetail from "./ContactDetail.js"; 

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null); 

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/contact")
      .then((response) => response.json())
      .then((data) => {        
        const sortedContacts = data.sort(
          (a, b) => new Date(b.fecha) - new Date(a.fecha)
        );
        setContacts(sortedContacts);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleContactClick = (contact) => { 
    setSelectedContact(contact);
  };

  const handleCloseModal = () => { 
    setSelectedContact(null);
  };

  return (
<div className="p-2 sm:p-4 w-full">
  <div className="overflow-auto h-full">
    <table className="table-auto w-full rounded-lg shadow-md">
      <thead className="sticky top-0 bg-custom-blue text-white">
        <tr className="text-xs sm:text-sm md:text-base">
          <th className="px-2 sm:px-4 py-2">Nombre</th>
          <th className="px-2 sm:px-4 py-2">Teléfono</th>
          <th className="px-2 sm:px-4 py-2">Correo</th>
        </tr>
      </thead>
      <tbody className="overflow-auto">
        {contacts.map((contact, index) => (
          <tr
            key={index}
            className={index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"}
            onClick={() => handleContactClick(contact)} 
          >
            <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">{contact.nombreCompleto}</td>
            <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">{contact.numeroTelefono}</td>
            <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">
              {contact.correoElectronico}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  {selectedContact && <ContactDetail contact={selectedContact} onClose={handleCloseModal} />}
</div>
  );
};

export default Contacts;
