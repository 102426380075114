import React from "react";
import { HashLink } from "react-router-hash-link";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import Banner from "../assets/img/quote-banner.jpg";

const QuoteNow = () => {
  const intl = useIntl();
  
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div
      className={`container mx-auto mt-24 mb-24 flex flex-col justify-center items-center transition-opacity duration-3000 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
      ref={ref}
    >
      <h1 className="text-4xl font-bold mb-4 text-custom-blue text-center sm:text-center md:text-center">
        {intl.formatMessage({ id: 'quoteNow.title' })}
      </h1>
      <HashLink smooth to="/quotation">
        <div className="py-8">
          <img src={Banner} alt="Banner" className="mt-4" />
        </div>
        <div className="flex justify-center">
          <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
            {intl.formatMessage({ id: 'quoteNow.button' })}
          </button>
        </div>
      </HashLink>
    </div>
  );
};

export default QuoteNow;