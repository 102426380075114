import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import English from './assets/locales/eng.json';
import Spanish from './assets/locales/esp.json';
import IntroPage from "./components/IntroPage.js";
import Home from "./components/Home.js";
import Services from "./components/Services.js";
import Quotation from "./components/Quotation.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import Dashboard from "./components/Dashboard.js";
import LoginPage from "./components/LoginPage.js";
import { AuthProvider } from "./components/AuthContext.js";
import { SearchContext } from './components/SearchContext.js'; 

function App() {
  const [searchResults, setSearchResults] = React.useState([]);
  const [locale, setLocale] = React.useState(localStorage.getItem('locale') || 'es');
  const [messages, setMessages] = React.useState(locale === 'en' ? English : Spanish);

  useEffect(() => {
    if (locale === 'en') {
      setMessages(English);
    } else {
      setMessages(Spanish);
    }
  }, [locale]);

  const changeLanguage = (lang) => {
    setLocale(lang);
    localStorage.setItem('locale', lang);
  };

  return (
    <AuthProvider>
      <SearchContext.Provider value={{ searchResults, setSearchResults }}>
        <IntlProvider locale={locale} messages={messages}>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/" element={<IntroPage />} />
                <Route path="/home" element={<Home onLanguageChange={changeLanguage} />} />
                <Route path="/services" element={<Services  onLanguageChange={changeLanguage} />} />
                <Route path="/quotation" element={<Quotation onLanguageChange={changeLanguage} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy onLanguageChange={changeLanguage} />} />
                <Route path="/dashboard/*" element={<Dashboard />} />  
                <Route path="/loginPage" element={<LoginPage />} />
              </Routes>
            </div>
          </Router>
        </IntlProvider>
      </SearchContext.Provider>
    </AuthProvider>
  );
}

export default App;