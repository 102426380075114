import React, { useState } from "react";
import facebookIcon from "../assets/icons/facebook.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import whatsappIcon from "../assets/icons/whatsapp.svg";
import closeIcon from "../assets/icons/close.svg"; 

const SocialButtons = () => {
  const [isWhatsAppOpen, setWhatsAppOpen] = useState(false);

  const toggleWhatsApp = () => {
    setWhatsAppOpen(!isWhatsAppOpen);
  };

  return (
    <>
      <div className="fixed left-0 top-1/2 transform -translate-y-1/2 px-8 flex flex-col z-50">
        <a
          href="https://www.facebook.com/809shipping"
          target="_blank"
          rel="noreferrer"
        >
          <button className="w-12 h-12 rounded-full bg-white flex items-center justify-center shadow-xl mb-4 transform transition-transform duration-300 hover:scale-125">
            <img src={facebookIcon} alt="Facebook" className="w-10 h-10" />
          </button>
        </a>
        <a
          href="https://www.instagram.com/809shipping/"
          target="_blank"
          rel="noreferrer"
        >
          <button className="w-12 h-12 rounded-full bg-white flex items-center justify-center shadow-xl transform transition-transform duration-300 hover:scale-125">
            <img src={instagramIcon} alt="Instagram" className="w-10 h-10" />
          </button>
        </a>
      </div>
      <div className="fixed right-0 bottom-0 mb-4 mr-4 z-50 flex flex-col-reverse pt-4">
        {!isWhatsAppOpen ? (
          <button
            className="w-14 h-14 rounded-full bg-custom-green flex items-center justify-center shadow-xl transform transition-transform duration-300 hover:scale-125"
            onClick={toggleWhatsApp}
          >
            <img src={whatsappIcon} alt="WhatsApp" className="w-10 h-10" />
          </button>
        ) : (
          <div className=" flex flex-col-reverse items-end animate-slideUp transform transition-duration-500">
            <button
              className="w-14 h-14 rounded-full bg-green-800 flex items-center justify-center shadow-xl transform transition-transform duration-300 hover:scale-125"
              onClick={toggleWhatsApp}
            >
              <img src={closeIcon} alt="Close" className="w-10 h-10" />
            </button>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B13056773881&text=Le+escribo+desde+su+p%C3%A1gina+web+para+mayor+informaci%C3%B3n+de+su+servicio...&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center space-x-2">
                <span className="text-sm text-white font-bold bg-custom-green rounded px-2 shadow-xl">
                  Miami
                </span>
                <button className="w-14 h-14 rounded-full bg-custom-green flex items-center justify-center shadow-xl transform transition-transform duration-300 hover:scale-125">
                  <img
                    src={whatsappIcon}
                    alt="WhatsApp"
                    className="w-10 h-10"
                  />
                </button>
              </div>
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B19179428795&text=Le+escribo+desde+su+p%C3%A1gina+web+para+mayor+informaci%C3%B3n+de+su+servicio...&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center space-x-2">
                <span className="text-sm text-white font-bold bg-custom-green rounded px-2 shadow-xl">
                  New York
                </span>
                <button className="w-14 h-14 rounded-full bg-custom-green flex items-center justify-center shadow-xl transform transition-transform duration-300 hover:scale-125">
                  <img
                    src={whatsappIcon}
                    alt="WhatsApp"
                    className="w-10 h-10"
                  />
                </button>
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default SocialButtons;
