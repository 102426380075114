import React, { useEffect, useState } from "react";
import UserDetail from "./UserDetail.js";
import AddUser from "./AddUser.js";
import addUserIcon from "../assets/icons/add-user.svg";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [isAddingUser, setIsAddingUser] = useState(false); // Nuevo estado

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/users")
      .then((response) => response.json())
      .then((data) => {
        const sortedUsers = data.sort(
          (a, b) => new Date(b.fecha) - new Date(a.fecha)
        );
        setUsers(sortedUsers);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleUserClick = (user) => {
    setSelectedUsers(user);
  };

  const handleAddUserClick = () => {
    setIsAddingUser(true); // Establecer el estado de agregar usuario en verdadero
  }

  const handleCloseModal = () => {
    setSelectedUsers(null);
    setIsAddingUser(false); // Establecer el estado de agregar usuario en falso
  };

  return (
    <div className="p-2 sm:p-4 w-full">
      <div className="overflow-auto h-full">
        <table className="table-auto w-full rounded-lg shadow-md">
          <thead className="sticky top-0 bg-custom-blue text-white">
            <tr className="text-xs sm:text-sm md:text-base">
              <th className="px-2 sm:px-4 py-2">Nombre</th>
              <th className="px-2 sm:px-4 py-2">Correo</th>
              <th className="px-2 sm:px-4 py-2">Rol</th>
            </tr>
          </thead>
          <tbody className="overflow-auto">
            {users.map((user, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"}
                onClick={() => handleUserClick(user)}
              >
                <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">
                  {user.nombreCompleto}
                </td>
                <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">
                  {user.correoElectronico}
                </td>
                <td className="border px-2 sm:px-4 py-2 text-xs sm:text-sm md:text-base">
                  {user.rol}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedUsers && (
        <UserDetail user={selectedUsers} onClose={handleCloseModal} />
      )}
      {isAddingUser && (
        <AddUser onClose={handleCloseModal} /> // Mostrar AddUser si isAddingUser es verdadero
      )}
      {localStorage.getItem("userRole") === "admin" && (
        <button
          className="fixed right-8 top-16 bg-[#CE1126] text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg shadow-gray-500 hover:bg-red-500"
          onClick={handleAddUserClick}
        >
          <img src={addUserIcon} alt="Add User" />
        </button>
      )}
    </div>
  );
};

export default Users;
