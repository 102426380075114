import React from "react";
import image1 from "../assets/img/engineer3.jpg";
import image2 from "../assets/img/engineer2.jpg";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";

const VisionMission = () => {
  const intl = useIntl();

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div id="visionmission" className="bg-gray-100">
      <div className="container w-full sm:w-4/5 mx-auto py-8" ref={ref}>
        <div
          className={`grid grid-cols-1 md:grid-cols-2 gap-4 transition-opacity duration-3000 ${
            inView ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="col-span-2 md:col-span-1 flex flex-col justify-center">
            <h1 className="px-4 text-2xl sm:text-4xl font-bold mb-4 text-custom-blue py-8">
              {intl.formatMessage({ id: "vision.title" })}
            </h1>
            <p className="mb-4 px-4 text-justify">
              {intl.formatMessage({ id: "vision.paragraph1" })}
            </p>
            <p className="mb-4 px-4 text-justify">
              {intl.formatMessage({ id: "vision.paragraph2" })}
            </p>
          </div>
          <div className="col-span-2 md:col-span-1 flex items-center justify-center">
            <img
              src={image1}
              alt={intl.formatMessage({ id: "vision.image1" })}
              className="rounded-xl shadow-lg"
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex items-center justify-center">
            <img
              src={image2}
              alt={intl.formatMessage({ id: "vision.image2" })}
              className="rounded-xl shadow-lg"
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex flex-col justify-center text-justify">
            <p className="px-4 mb-4">
              {intl.formatMessage({ id: "vision.paragraph3" })}
            </p>
            <p className="px-4 mb-4">
              {intl.formatMessage({ id: "vision.paragraph4" })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
