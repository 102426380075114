import React from "react";

const ContactDetail = ({ contact, onClose }) => {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full md:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-custom-blue"
                  id="modal-title"
                >
                  {contact.nombreCompleto}
                </h3>
                <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
                  <p className="text-sm text-gray-500 font-semibold">
                    Nombre Completo:
                  </p>
                  <p className="text-sm text-gray-500">
                    {contact.nombreCompleto}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    Número de Teléfono:
                  </p>
                  <p className="text-sm text-gray-500">
                    {contact.numeroTelefono}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    Correo Electrónico:
                  </p>
                  <p className="text-sm text-gray-500">
                    {contact.correoElectronico}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    Mensaje:
                  </p>
                  <p className="text-sm text-gray-500">{contact.mensaje}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-blue text-base font-medium text-white hover:bg-custom-red  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
