import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useIntl, FormattedMessage } from 'react-intl';
import logo809 from "../assets/img/809-logo.png";
import usFlag from "../assets/img/usa.png";
import esFlag from "../assets/img/spa.png";
import menuIcon from "../assets/icons/menu.svg";
import userIcon from "../assets/icons/user.svg";

const Navbar = ({ onLanguageChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full fixed top-0 z-50 bg-white shadow">
      <div className="w-full md:w-4/5 mx-auto">
        <nav className="flex flex-wrap justify-between p-6 h-24">
          <div className="w-full md:w-1/6 flex justify-between">
            <Link to="/home">
              <img src={logo809} alt={intl.formatMessage({ id: 'logo' })} className="w-22 h-14" />
            </Link>
            <button className="md:hidden" onClick={toggleMenu}>
              <img src={menuIcon} alt={intl.formatMessage({ id: 'menu' })} />
            </button>
          </div>
          <div
            className={`bg-white w-full md:w-4/6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 pt-4 justify-center text-center md:items-center  rounded-b-lg  ${
              isOpen ? "block" : "hidden"
            } md:block`}
          >
            <Link
              to="/home"
              className="text-black px-4 border-b-4 border-transparent hover:border-custom-red"
              onClick={toggleMenu}
            >
              <FormattedMessage id="inicio" />
            </Link>
            <HashLink
              smooth
              to="/home#aboutus"
              className="text-black px-4 border-b-4 border-transparent hover:border-custom-red"
              onClick={toggleMenu}
            >
              <FormattedMessage id="nosotros" />
            </HashLink>
            <Link
              to="/services"
              className="text-black px-4 border-b-4 border-transparent hover:border-custom-red"
              onClick={toggleMenu}
            >
              <FormattedMessage id="servicios" />
            </Link>
            <HashLink
              smooth
              to="/home#tracking"
              className="text-black px-4 border-b-4 border-transparent hover:border-custom-red"
              onClick={toggleMenu}
            >
              <FormattedMessage id="rastrear" />
            </HashLink>
            <Link
              to="/quotation"
              className="font-bold px-4 py-2 rounded bg-custom-blue text-white hover:bg-custom-red  transition-transform duration-300 hover:scale-125"
              onClick={toggleMenu}
            >
              <FormattedMessage id="cotizar" />
            </Link>
            <HashLink
              smooth
              to="/home#contactus"
              className="text-black  px-4 border-b-4 border-transparent hover:border-custom-red"
              onClick={toggleMenu}
            >
              <FormattedMessage id="contacto" />
            </HashLink>
          </div>
          <div className="w-full md:w-1/6 flex space-x-2 justify-end">
            <button onClick={() => onLanguageChange("es")}>
              <img
                src={esFlag}
                alt="ESP"
                className="w-6 h-6 rounded-full transform transition-transform duration-300 hover:scale-110"
              />
            </button>
            <button onClick={() => onLanguageChange("en")}>
              <img
                src={usFlag}
                alt="ENG"
                className="w-6 h-6 rounded-full transform transition-transform duration-300 hover:scale-110"
              />
            </button>
            <button>
              <Link to="/loginPage">
                <img
                  src={userIcon}
                  alt={intl.formatMessage({ id: 'login' })}
                  className="bg-custom-red w-6 h-6 rounded-full transform transition-transform duration-300 hover:scale-110"
                />
              </Link>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;