import React from "react";
import { useIntl } from "react-intl";
import { useInView } from "react-intersection-observer";
import Video from "../assets/video/comercial2.mp4";
import Bandera from "../assets/img/bandera.jpg";

const AboutUs = () => {
  const intl = useIntl();
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <div
      className={`container mx-auto mt-24 mb-24 transition-opacity duration-3000 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
      ref={ref}
    >
      <div className="flex flex-col md:flex-row">
        <div
          className="w-full md:w-1/2 p-4 flex flex-col justify-center p-8 bg-cover bg-left-top relative rounded-xl"
          style={{ backgroundImage: `url(${Bandera})` }}
        >
          <div className="absolute inset-0 bg-white opacity-80"></div>
          <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-left py-8 text-custom-blue z-10">
            {intl.formatMessage({ id: "AboutUs.welcome" })}
          </h1>
          <p className="text-sm sm:text-lg z-10 text-justify">
            {intl.formatMessage({ id: "AboutUs.description" })}
          </p>
        </div>
        <div className="w-full md:w-1/2 px-8 ">
          <video
            src={Video}
            alt="About Us"
            className="w-full h-full object-contain rounded-xl shadow-lg"
            autoPlay
            loop
            muted
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
