import React from "react";
import boatIcon from "../assets/icons/boat.svg";
import truckIcon from "../assets/icons/truck.svg";
import planeIcon from "../assets/icons/plane.svg";
import doorIcon from "../assets/icons/door.svg";
import warehouseIcon from "../assets/icons/warehouse.svg";
import shipmentIcon from "../assets/icons/shipment.svg";
import { HashLink } from "react-router-hash-link";
import { useIntl } from "react-intl";

const ServiceBanner = () => {
  const intl = useIntl();

  return (
    <div className="flex flex-wrap justify-center items-center mx-auto rounded-lg bg-gray-200 w-full md:w-4/5 py-10 flex-col md:flex-row">
      <div className="flex flex-row w-full md:w-1/2 justify-center items-center">
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#cargamaritima">
            <img
              src={boatIcon}
              alt="Boat Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "cargaMaritima" })}
            </h2>
          </HashLink>
        </div>
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#cargaAerea">
            <img
              src={planeIcon}
              alt="Truck Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "cargaAerea" })}
            </h2>
          </HashLink>
        </div>
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#transporteTerrestre">
            <img
              src={truckIcon}
              alt="Plane Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "transporteTerrestre" })}
            </h2>
          </HashLink>
        </div>
      </div>
      <div className="flex flex-row w-full md:w-1/2 justify-center items-center">
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#almacenaje">
            <img
              src={warehouseIcon}
              alt="Warehouse Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "almacenaje" })}
            </h2>
          </HashLink>
        </div>
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#puertaApuerta">
            <img
              src={doorIcon}
              alt="Door Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "puertaAPuerta" })}
            </h2>
          </HashLink>
        </div>
        <div className="w-full md:w-1/3 flex justify-center items-center p-4">
          <HashLink to="/services#despachoAduanero">
            <img
              src={shipmentIcon}
              alt="Shipment Icon"
              className="w-24 md:w-40 h-24 md:h-40 transform transition-transform duration-500 hover:scale-125"
            />
            <h2 className="text-lg font-bold text-custom-blue text-center">
            {intl.formatMessage({ id: "despachoAduanero" })}
            </h2>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;
