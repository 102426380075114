import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../assets/img/000.jpeg";
import img2 from "../assets/img/001.jpeg";
import img3 from "../assets/img/002.jpeg";
import img4 from "../assets/img/003.jpeg";
import img5 from "../assets/img/004.jpeg";
import img6 from "../assets/img/005.jpeg";
import img7 from "../assets/img/006.jpeg";
import img8 from "../assets/img/007.jpeg";
import img9 from "../assets/img/008.jpg";
import img10 from "../assets/img/009.jpg";
import img11 from "../assets/img/010.jpg";
import img12 from "../assets/img/011.jpg";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const MyCarousel = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];

  return (
    <div className="w-full  mx-auto py-8 bg-white">
    <Carousel
      responsive={responsive}
      infinite={true}
      additionalTransfrom={0}
      autoPlay={true}
      autoPlaySpeed={2000}
    >
      {images.map((image, index) => (
        <img
          key={index}
          className="object-cover w-full"
          src={image}
          alt={`Carousel ${index}`}
          style={{ width: '300px', height: '300px', borderRadius: '15px', boxShadow:'0px 5px 5px 0px rgba(0.75,0.75,0.75,0.75)'  }} 
        />
      ))}
    </Carousel>
    </div>
  );
};


export default MyCarousel;