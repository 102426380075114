import React from "react";
import { useIntl } from 'react-intl';
import bgImg from "../assets/img/transporte-logistica-1.jpg";


const Why809 = () => {
  const intl = useIntl();

  return (
    <div
      className="relative flex justify-center items-center mx-auto p-8"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-custom-red opacity-50"></div>
      <div className="relative flex flex-col md:flex-row w-full text-white">
        <div className="w-full md:w-2/5 p-4 mx-4">
          <h1 className="text-left text-4xl font-bold p-8">
            {intl.formatMessage({ id: "whyChoose" })}
          </h1>
          <p className="text-xl p-8">
          {intl.formatMessage({ id: "ourPosition" })}
          </p>
        </div>
        <div className="w-full md:w-3/5 p-4 flex flex-col justify-center text-center">
          <div className="flex flex-col md:flex-row justify-between p-8">
            <div className="w-full md:w-1/2">
              <h1 className="text-2xl font-bold">1,000,000</h1>
              <hr className="w-4/5 mx-auto" />
              <p className="text-xl">
              {intl.formatMessage({ id: "airCargo" })}
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <h1 className="text-2xl font-bold">6,000</h1>
              <hr className="w-4/5 mx-auto" />
              <p className="text-xl">
              {intl.formatMessage({ id: "successfulShipments" })}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between p-8">
            <div className="w-full md:w-1/2">
              <h1 className="text-2xl font-bold">7,000 TEUS</h1>
              <hr className="w-4/5 mx-auto" />
              <p className="text-xl">
              {intl.formatMessage({ id: "warehouseLoad" })}
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <h1 className="text-2xl font-bold">1,500</h1>
              <hr className="w-4/5 mx-auto" />
              <p className="text-xl">
              {intl.formatMessage({ id: "satisfiedCustomers" })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why809;