import React, { useEffect, useState } from "react";
import QuoteDetail from "./QuoteDetail.js"; 

const Quotes = () => {
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null); 

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/quote")
      .then((response) => response.json())
      .then((data) => {
        const sortedQuotes = data.sort(
          (a, b) => new Date(b.fecha) - new Date(a.fecha)
        );
        setQuotes(sortedQuotes);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleQuoteClick = (quote) => {
    setSelectedQuote(quote);
  };

  const handleCloseModal = () => { 
    setSelectedQuote(null);
  };

  return (
    <div className="p-4 w-full">
      {/* <div>
    <SearchBar />
  </div> */}
      <div className="h-full">
  <table className="table-auto w-full rounded-lg shadow-md">
    <thead className="sticky top-0 bg-custom-blue text-white">
      <tr className="text-xs sm:text-sm md:text-base">
        <th className="px-4 py-2">Nombre</th>
        <th className="px-4 py-2">Empresa</th>
        <th className="px-4 py-2">Teléfono</th>
        <th className="px-4 py-2">Servicio</th>
        <th className="px-4 py-2">País</th>
      </tr>
    </thead>
    <tbody className="overflow-auto">
      {quotes.map((quote, index) => (
        <tr
          key={index}
          className={index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"}
          onClick={() => handleQuoteClick(quote)} 
        >
          <td className="border px-4 py-2 text-xs sm:text-sm md:text-base">{quote.nombreCompleto}</td>
          <td className="border px-4 py-2 text-xs sm:text-sm md:text-base">{quote.nombreEmpresa}</td>
          <td className="border px-4 py-2 text-xs sm:text-sm md:text-base">{quote.numeroTelefono}</td>
          <td className="border px-4 py-2 text-xs sm:text-sm md:text-base">{quote.tipoServicio}</td>
          <td className="border px-4 py-2 text-xs sm:text-sm md:text-base">{quote.pais}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
{selectedQuote && (
  <QuoteDetail quote={selectedQuote} onClose={handleCloseModal} />
)}
          </div>
  );
};

export default Quotes;
