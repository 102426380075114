import React, { useContext, useEffect, useState } from "react";
import {  BrowserRouter as Router,  Routes,  Route,  Link,  useNavigate,} from "react-router-dom";
import logoImage from "../assets/img/809-logo-alpha-2.png";
import logoutIcon from "../assets/icons/logout.svg";
import usersIcon from "../assets/icons/users.svg";
import shoppingCartIcon from "../assets/icons/shopping-cart.svg";
import phoneIcon from "../assets/icons/phone-blue.svg";
import layoutDashboardIcon from "../assets/icons/layout-dashboard.svg";
import Statistics from "./Statistics.js";
import Quotes from "./Quotes.js";
import Contacts from "./Contacts.js";
import Users from "./Users.js";
import { KJUR } from "jsrsasign";
import { AuthContext } from "./AuthContext.js";
import { SearchContext } from "./SearchContext.js";


// Componente Sidebar
const Sidebar = ({ isOpen, setIsOpen }) => {
  const token = localStorage.getItem("token");
  let nombreUsuario = "No hay usuario logueado";

  if (token) {
    const decodedToken = KJUR.jws.JWS.parse(token);
    nombreUsuario = decodedToken.payloadObj.correoElectronico.split('@')[0];
  }

  return (
    <div className={`fixed md:relative bg-gray-200 p-4 h-full overflow-auto transform md:transform-none transition-transform duration-200 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 w-64 md:w-1/6 z-50`}>
      <div className="text-custom-blue text-center text-2xl ">
        <img
          src={logoImage}
          alt="Logo"
          className="w-1/2 mx-auto justify-center items-center py-8"
        />
        <hr className=" w-9/10 mx-auto border-t-1 border-gray-300 mb-8" />
        {nombreUsuario !== "No hay usuario logueado"
          ? <p>Bienvenido <br /> {nombreUsuario}</p>
          : "No hay usuario logueado"}
        <hr className=" w-9/10 mx-auto border-t-1 border-gray-300 my-8" />
      </div>
      <div className="mt-8 text-custom-blue text-xl ">
        <ul>
          <li className="p-2 hover:bg-gray-300 hover:rounded-lg">
            <img
              src={layoutDashboardIcon}
              alt="Dashboard Icon"
              className="inline-block mr-2"
            />
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="p-2 hover:bg-gray-300 hover:rounded-lg">
            <img
              src={shoppingCartIcon}
              alt="Quotes Icon"
              className="inline-block mr-2"
            />
            <Link to="quotes">Cotizaciones</Link>
          </li>
          <li className="p-2 hover:bg-gray-300 hover:rounded-lg">
            <img
              src={phoneIcon}
              alt="Contacts Icon"
              className="inline-block mr-2"
            />
            <Link to="contacts">Contactos</Link>
          </li>
          <li className="p-2 hover:bg-gray-300 hover:rounded-lg">
            <img
              src={usersIcon}
              alt="Contacts Icon"
              className="inline-block mr-2"
            />
            <Link to="users">Usuarios</Link>
          </li>
          <li className="p-2 hover:bg-gray-300 hover:rounded-lg text-custom-red">
            <img
              src={logoutIcon}
              alt="Logout Icon"
              className="inline-block mr-2"
            />
            <button
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/home";
              }}
            >
              Cerrar sesión
            </button>
          </li>
        </ul>
      </div>
      <button className=" lg:hidden bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => setIsOpen(false)}>Cerrar</button>
    </div>
  );
};

// Componente MainArea
const MainArea = () => {
  const { searchResults } = useContext(SearchContext);

  return (
    <div className="w-full md:w-5/6 h-full overflow-auto">
      <Routes>
        <Route path="quotes" element={<Quotes />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="users" element={<Users />} />
        <Route path="/" element={<Statistics />} />
      </Routes>
      {searchResults.map((result, index) => (
        <Quotes key={index} data={result} />
      ))}
    </div>
  );
};

// Componente Dashboard
const Dashboard = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/loginPage");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <button className="md:hidden lg:hidden bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-1/6 rounded-full" onClick={() => setIsSidebarOpen(true)}>Menú</button>
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <MainArea />
    </div>
  );
};

export default Dashboard;
