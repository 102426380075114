import React from "react";
import phoneIcon from "../assets/icons/phone.svg";
import emailIcon from "../assets/icons/mail.svg";

const PhoneBar = () => {
  return (
    <div className="w-full bg-custom-blue flex flex-col sm:flex-row justify-center py-2 text-white mt-24 ">
      <div className="flex justify-center px-4 mb-2 sm:mb-0">
        <a href="tel:+18668097447" className="flex items-center text-white">
          <img src={phoneIcon} alt="Phone Icon" className="px-2" />
          <h1>GLOBAL (1) 866 809 7447</h1>
        </a>
      </div>
      <div className="flex justify-center px-4 mb-2 sm:mb-0">
        <a href="tel:+13056773881" className="flex items-center text-white">
          <img src={phoneIcon} alt="Phone Icon" className="px-2" />
          <h1>MIAMI (305) 677 3881</h1>
        </a>
      </div>
      <div className="flex justify-center px-4 mb-2 sm:mb-0">
        <a href="tel:+19179428795" className="flex items-center text-white">
          <img src={phoneIcon} alt="Phone Icon" className="px-2" />
          <h1>NEW YORK (917) 942 8795</h1>
        </a>
      </div>
      <div className="flex justify-center px-4">
        <a
          href="mailto:INFO@809SHIPPING.COM"
          className="flex items-center text-white"
        >
          <img src={emailIcon} alt="Email Icon" className="px-2" />
          <h1>INFO@809SHIPPING.COM</h1>
        </a>
      </div>
    </div>
  );
};

export default PhoneBar;
