import React from "react";
import LogoImage from "../assets/img/809-logo.png";
import { Link } from "react-router-dom";
import BgFooter from "../assets/img/footer-truck-cargo2.jpg";

const Footer = () => {
  return (
    <div className="w-full bg-white" style={{ backgroundImage: `url(${BgFooter})`, backgroundSize: "cover" }}>
      <div className="w-full sm:w-4/5 mx-auto flex flex-col sm:flex-row justify-around items-center p-8">
        <div className="w-full sm:w-1/3 flex justify-center items-center mb-4 sm:mb-0">
          <img src={LogoImage} alt="Logo" />
        </div>
        <div className="w-full sm:w-1/3 justify-center items-center px-4 mb-4 sm:mb-0">
          <ul>
            <h1 className="font-bold text-xl text-custom-red">MIAMI</h1>
            <Link
              to="https://www.google.com/maps/place/2000+NW+97th+Ave,+Doral,+FL+33172,+EE.+UU./@25.7928655,-80.3569683,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b947de247ce5:0xb2efd10ba8e88add!8m2!3d25.7928607!4d-80.3543934!16s%2Fg%2F11bw40_fdl?hl=es-ES&entry=ttu"
              target="_blank"
            >
              <li className="text-white">2000 Nw 97th Ave Miami FL 33172</li>
            </Link>
            <li className="text-white">+1 (305) 677 3881</li>
            <li className="text-white">+1 (866) 809 7447</li>
          </ul>
        </div>
        <div className="w-full sm:w-1/3 justify-center items-center px-4">
          <ul>
            <h1 className="font-bold text-xl text-custom-red">NEW YORK</h1>
            <Link
              to="https://www.google.com/maps/place/608+Tiffany+St,+Bronx,+NY+10474,+EE.+UU./@40.8121567,-73.8950791,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2f5a815373ddb:0xe7a6d1a706598140!8m2!3d40.8121527!4d-73.8904657!16s%2Fg%2F11c4g1yc85?hl=es-ES&entry=ttu"
              target="_blank"
            >
              <li className="text-white">608 Tiffany St Bronx NY 10474</li>
            </Link>
            <li className="text-white">+1 (917) 942 8795</li>
            <li className="text-white">+1 (866) 809 7447</li>
          </ul>
        </div>
      </div>
      <div className="bg-custom-blue text-white w-full flex flex-col sm:flex-row justify-center items-center p-4 text-sm">
        <div className="flex-grow flex justify-center items-center px-4 mb-2 sm:mb-0">
          <Link to="https://mgarcia.top" target="_blank">
            <p>By mgarcia</p>
          </Link>
        </div>
        <div className="flex-grow flex justify-center items-center px-4 mb-2 sm:mb-0">
          <p>© 2024 809 SHIPPING. ALL RIGHTS RESERVED.</p>
        </div>
        <div className="flex-grow flex justify-center items-center px-4">
          <Link to="/privacy-policy" target="_blank">
            <p>Privacy Policy</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
