import React from "react";
import { HashLink } from "react-router-hash-link";
// import portVideo from "../assets/video/Container-Terminal2.mp4";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useIntl } from "react-intl";
import image1 from "../assets/img/port3.jpg";
import image2 from "../assets/img/plain-air2.jpg";
import image3 from "../assets/img/truck-cargo.jpg";
import image4 from "../assets/img/transporte-logistica-1.jpg";
import image5 from "../assets/img/plain-cargo.jpg";

const Banner = () => {
  const intl = useIntl();

  return (
    <div className="relative">
      <div className="w-full h-2/3">
        <Carousel
          autoPlay
          infiniteLoop
          interval={3000}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          stopOnHover={false}
        >
          <img src={image1} alt="Puerto" />
          <img src={image2} alt="Imagen 2" />
          <img src={image3} alt="Imagen 3" />
          <img src={image4} alt="Imagen 4" />
          <img src={image5} alt="Imagen 5" />
        </Carousel>
      </div>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute inset-0 flex items-center justify-center flex-col text-center p-4 md:p-8">
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-white mb-2 sm:mb-4">
          {intl.formatMessage({ id: "title" })}
        </h1>
        <p className="hidden sm:block text-xs sm:text-sm md:text-lg text-white whitespace-pre-wrap mt-2 sm:mt-4 overflow-wrap break-word">
          {intl.formatMessage({ id: "bannerDescription1" })}
          <br />
          {intl.formatMessage({ id: "bannerDescription2" })}
          <br />
          {intl.formatMessage({ id: "bannerDescription3" })}
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-4 py-8">
          <HashLink smooth to="/quotation">
            <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
              {intl.formatMessage({ id: "quoteNowButton" })}
            </button>
          </HashLink>
          <HashLink smooth to="/home#tracking">
            <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
              {intl.formatMessage({ id: "trackShipmentButton" })}
            </button>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
