import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs.js";
import NavBar from "./NavBar.js";
import PhoneBar from "./PhoneBar.js";
import Footer from "./Footer.js";
import SocialButtons from "./SocialButtons.js";
import Carousel from './MyCarousel.jsx';
import { useIntl } from "react-intl";
import BgImage from "../assets/img/truck-cargo2.jpg";
import CheckIcon from "../assets/icons/check.svg";
import { useInView } from "react-intersection-observer";
import Banner from "../assets/img/quote-banner.jpg";


const Quotation = ({ onLanguageChange }) => {
  const intl = useIntl();

  const initialState = {
    nombreCompleto: "",
    numeroTelefono: "",
    nombreEmpresa: "",
    correoElectronico: "",
    pais: "",
    ciudad: "",
    codigoPostal: "",
    tipoServicio: "",
    observaciones: "",
    privacyPolicy: false,
  };

  const [quoteInfo, setQuoteInfo] = useState(initialState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    setQuoteInfo({
      ...quoteInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!quoteInfo.privacyPolicy) {
      alert("Por favor acepta las políticas de privacidad");
      return;
    }

    await handleSubmitToServer();
    setQuoteInfo(initialState);
  };
  const handleSubmitToServer = async () => {
    // console.log("Datos del formulario:", quoteInfo);
  
    const dataToSend = {
      ...quoteInfo,
      numeroTelefono: Number(quoteInfo.numeroTelefono),
      codigoPostal: Number(quoteInfo.codigoPostal),
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/quote`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Enviar correo electrónico
      let mailOptions = {
        from: '"Cotizaciones" <acadevmy0410@gmail.com>', // dirección de correo del remitente
        to: '809shippingg@gmail.com', // lista de receptores
        subject: 'Nueva Cotización', // Línea de asunto
        text: `Nombre: ${dataToSend.nombreCompleto}\nCorreo: ${dataToSend.correoElectronico}\nTeléfono: ${dataToSend.numeroTelefono}\nEmpresa: ${dataToSend.nombreEmpresa}\nPaís: ${dataToSend.pais}\nCiudad: ${dataToSend.ciudad}\nCódigo Postal: ${dataToSend.codigoPostal}\nTipo de Servicio: ${dataToSend.tipoServicio}\nObservaciones: ${dataToSend.observaciones}`, // cuerpo de texto plano
      };
  
      const emailResponse = await fetch(`${process.env.REACT_APP_API_URL}/sendmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailOptions),
      });
  
      if (emailResponse.ok) {
        console.log('Correo enviado exitosamente');
      } else {
        console.error('Error al enviar el correo');
      }
  
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="mt-24">
      <NavBar onLanguageChange={onLanguageChange} />
      <PhoneBar />
      <div
        className="w-full h-screen flex relative"
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute left-0 right-0 bg-black opacity-50 h-full w-full"></div>
        <div className="p-4 absolute flex flex-col justify-center items-center h-full w-full">
          <h1 className=" text-6xl font-bold text-white mb-2 sm:mb-4 text-center">
            {intl.formatMessage({ id: "quotation.title" })}
          </h1>
          <p className="text-xl text-white whitespace-pre-wrap mt-2 sm:mt-4 overflow-wrap break-word text-center">
            {intl.formatMessage({ id: "quotation.description" })}
          </p>
        </div>
      </div>
      <div>
        <div className="w-full sm:w-4/5 mx-auto flex flex-col py-16">
          <div className="mb-4 px-8">
            <h1 className="text-4xl font-bold mb-4 text-custom-blue sm:text-center md:text-center">
              {intl.formatMessage({ id: "quotation.beyond.logistics" })}
            </h1>
            <p className="text-lg mb-4 py-8 text-justify">
              {intl.formatMessage({ id: "quotation.in.809.shipping" })}
            </p>
          </div>
          <div className="px-8 flex flex-col sm:flex-row">
            <div className="w-full sm:w-1/2 pr-4">
              <div className="flex items-start mb-4">
                <img src={CheckIcon} alt="Check" className="mr-2" />
                <p className="text-lg mb-4 text-justify">
                  {intl.formatMessage({ id: "quotation.transport_services" })}
                </p>
              </div>
              <div className="flex items-start mb-4">
                <img src={CheckIcon} alt="Check" className="mr-2" />
                <p className="text-lg mb-4 text-justify">
                  {intl.formatMessage({ id: "quotation.foreign_trade" })}
                </p>
              </div>
              <div className="flex items-start mb-4">
                <img src={CheckIcon} alt="Check" className="mr-2" />
                <p className="text-lg mb-4 text-justify">
                  {intl.formatMessage({ id: "quotation.storage.p" })}
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 pl-4">
              <div className="flex items-start mb-4">
                <img src={CheckIcon} alt="Check" className="mr-2" />
                <p className="text-lg mb-4 text-justify">
                  {intl.formatMessage({ id: "quotation.logistic_partner" })}
                </p>
              </div>
              <div className="flex items-start mb-4">
                <img src={CheckIcon} alt="Check" className="mr-2" />
                <p className="text-lg mb-4 text-justify">
                  {intl.formatMessage({ id: "quotation.renewable_energies" })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="w-full sm:w-4/5 mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full p-8 flex flex-col justify-center">
              <h1 className="text-4xl font-bold mb-4 text-custom-blue">
                {intl.formatMessage({ id: "quotation.basicData" })}
              </h1>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="nombreCompleto"
                    >
                      {intl.formatMessage({ id: "fullName" })}
                    </label>
                    <input
                      name="nombreCompleto"
                      id="nombreCompleto"
                      onChange={handleChange}
                      value={quoteInfo.nombreCompleto || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({ id: "fullName" })}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="numeroTelefono"
                    >
                      {intl.formatMessage({ id: "quotation.phoneNumber" })}
                    </label>
                    <input
                      id="numeroTelefono"
                      name="numeroTelefono"
                      onChange={handleChange}
                      value={quoteInfo.numeroTelefono || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({
                        id: "quotation.phoneNumber",
                      })}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="nombreEmpresa"
                    >
                      {intl.formatMessage({ id: "quotation.companyName" })}
                    </label>
                    <input
                      id="nombreEmpresa"
                      name="nombreEmpresa"
                      onChange={handleChange}
                      value={quoteInfo.nombreEmpresa || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({
                        id: "quotation.companyName",
                      })}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="correoElectronico"
                    >
                      {intl.formatMessage({ id: "quotation.email" })}
                    </label>
                    <input
                      id="correoElectronico"
                      name="correoElectronico"
                      onChange={handleChange}
                      value={quoteInfo.correoElectronico || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({
                        id: "quotation.email",
                      })}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-1/3 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="pais"
                    >
                      {intl.formatMessage({ id: "quotation.country" })}
                    </label>
                    <input
                      id="pais"
                      name="pais"
                      onChange={handleChange}
                      value={quoteInfo.pais || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({
                        id: "quotation.country",
                      })}
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="ciudad"
                    >
                      {intl.formatMessage({ id: "quotation.city" })}
                    </label>
                    <input
                      id="ciudad"
                      name="ciudad"
                      onChange={handleChange}
                      value={quoteInfo.ciudad || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({ id: "quotation.city" })}
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="codigoPostal"
                    >
                      {intl.formatMessage({ id: "quotation.postalCode" })}
                    </label>
                    <input
                      id="codigoPostal"
                      name="codigoPostal"
                      onChange={handleChange}
                      value={quoteInfo.codigoPostal || ""}
                      required
                      className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                      placeholder={intl.formatMessage({
                        id: "quotation.postalCode",
                      })}
                    />
                  </div>
                </div>
                <h1 className="text-4xl font-bold mb-4 text-custom-blue">
                  {intl.formatMessage({ id: "quotation.serviceType" })}
                </h1>
                <div className="flex justify-center items-center">
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="carga maritima"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.seaFreight" })}
                  </label>
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="carga aerea"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.airFreight" })}
                  </label>
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="transporte terrestre"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.landTransport" })}
                  </label>
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="almacenaje"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.storage" })}
                  </label>
                </div>
                <div className="flex justify-center items-center">
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="despacho aduanero"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.customsClearance" })}
                  </label>
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="puerta a puerta"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.doorToDoor" })}
                  </label>
                  <label className="mx-2">
                    <input
                      type="radio"
                      name="tipoServicio"
                      value="sevicios especiales"
                      onChange={handleChange}
                    />
                    {intl.formatMessage({ id: "quotation.specialServices" })}
                  </label>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="observaciones"
                  >
                    {intl.formatMessage({ id: "quotation.observations" })}
                  </label>
                  <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="observaciones"
                    name="observaciones"
                    onChange={handleChange}
                    value={quoteInfo.observaciones || ""}
                    placeholder={intl.formatMessage({
                      id: "quotation.messageSubject",
                    })}
                    rows="5"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="privacyPolicy" className="text-sm">
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      name="privacyPolicy"
                      checked={quoteInfo.privacyPolicy}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    {intl.formatMessage({ id: "privacyPolicy" })}{" "}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      className="text-blue-500 hover:underline"
                    >
                      {intl.formatMessage({ id: "privacyPolicyLink" })}
                    </Link>
                  </label>
                </div>
                <div className="mb-4">
                  <button
                    type="submit"
                    className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110"
                  >
                    {intl.formatMessage({ id: "submit" })}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-white flex flex-col justify-center items-center h-full py-4 sm:py-8 md:py-24 px-4 sm:px-8 md:px-12">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-center text-4xl font-bold mb-2 sm:mb-3 md:mb-4 text-custom-blue">
              {intl.formatMessage({ id: "quotation.24.hours" })}
            </h1>
            <div className="py-8">
              <img src={Banner} alt="Banner" className="mt-4" />
            </div>
            <p className="text-center  sm:text-base md:text-lg lg:text-xl xl:text-2xl">
              {intl.formatMessage({ id: "quotation.24.hours.description" })}
            </p>
          </div>
        </div>
        <Carousel />
        <ContactUs />
        <Footer />
        <SocialButtons />
      </div>
    </div>
  );
};

export default Quotation;
