import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { KJUR } from "jsrsasign";

function LoginPage() {
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleRequest = async (url, method, body = null) => {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    const response = await fetch(url, options);
    return response;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
  
    if (!correoElectronico || !contrasena) {
      alert("Por favor, introduce tu correo electrónico y contraseña");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ correoElectronico, contrasena })
      });
  
      if (!response.ok) {
        throw new Error(`Error de inicio de sesión: ${response.status}`);
      }

      const data = await response.json();
      if (!data.token) {
        throw new Error("No se recibió ningún token del servidor.");
      }
  
      localStorage.setItem("token", data.token);
      const decodedToken = KJUR.jws.JWS.parse(data.token);
  
      if (!decodedToken.payloadObj.rol) {
        throw new Error("El rol del usuario no está en el token.");
      }
  
      localStorage.setItem("userRole", decodedToken.payloadObj.rol);
      console.log("Rol del usuario:", decodedToken.payloadObj.rol);

      const rolesPermitidos = ["admin", "estandar"];
      if (!rolesPermitidos.includes(decodedToken.payloadObj.rol)) {
        throw new Error("Acceso denegado: el rol del usuario no está permitido.");
      }
      
      window.location.href = "/dashboard";
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 border border-gray-300 rounded-lg p-8">
        <h1 className="mt-6 text-center text-4xl font-bold text-custom-blue">
          Iniciar sesión
        </h1>
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label className="sr-only">Nombre de usuario</label>
              <input
                type="text"
                value={correoElectronico}
                onChange={(e) => setCorreoElectronico(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Nombre de usuario"
              />
            </div>
            <div>
              <label className="sr-only">Contraseña</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={contrasena}
                  onChange={(e) => setContrasena(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Contraseña"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6 text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20l7.682-7.318a4.5 4.5 0 00-6.364-6.364L12 7l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6 text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20l7.682-7.318a4.5 4.5 0 00-6.364-6.364L12 7l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            sitekey="your-recaptcha-site-key"
            className="mt-3 rounded-md shadow-sm -space-y-px "
          />
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Iniciar sesión
            </button>
          </div>
        </form>
        <div className="text-center">
          <a
            href="/recover-password"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            ¿Olvidaste tu contraseña?
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
