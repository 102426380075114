import React from "react";
import BgVideo from "../assets/video/rutas.mp4";
import { useIntl } from 'react-intl';

const Tracking = () => {
  const intl = useIntl();

  return (
    <div id="tracking" className="w-full md:w-4/5 mx-auto py-16">
      <h1 className="text-2xl md:text-4xl font-bold mb-4 text-custom-blue text-center justify-center items-center">
      {intl.formatMessage({ id: "packageTracking" })}
      </h1>
      <p className="mb-4 text-sm md:text-lg text-center">
      {intl.formatMessage({ id: "enterTrackingNumber" })}
      </p>
      <div
        className="tracking-module p-4 justify-center items-center mx-auto relative flex items-center"
      >
        <video autoPlay loop muted className="absolute w-full h-100% object-cover" style={{ zIndex: -1 }}>
          <source src={BgVideo} type="video/mp4" />
        </video>
        {/* <div className="absolute inset-0 bg-white opacity-80"></div> */}
        <div className="iframe-container relative z-10 w-full">
          <iframe
            id="advanced_iframe"
            name="advanced_iframe"
            src="https://ikomsoft.com/traking_web.php?cp=43"
            className="w-full" // Ancho completo
            style={{ height: "350px" }} // Altura fija
            // frameBorder="0"
            allowtransparency="true"
            title="Tracking iframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Tracking;