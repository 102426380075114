import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactImage from "../assets/img/salesman.png";
import { useIntl } from "react-intl";
import { useInView } from "react-intersection-observer";

const ContactUs = () => {
  const intl = useIntl();

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  const initialState = {
    nombreCompleto: "",
    correoElectronico: "",
    numeroTelefono: "",
    mensaje: "",
    privacyPolicy: false,
  };

  const [contactInfo, setContactInfo] = useState(initialState);

  const handleChange = (event) => {
    setContactInfo({
      ...contactInfo,
      [event.target.name]:
        event.target.name === "privacyPolicy"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmitToServer = async () => {
    // console.log("Datos del formulario:", contactInfo);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactInfo),
      });
  
      if (response.ok) {
        setContactInfo(initialState);
  
        // Enviar correo electrónico
        let mailOptions = {
          from: '"Contacto" <acadevmy0410@gmail.com>', // dirección de correo del remitente
          to: '809shippingg@gmail.com', // lista de receptores
          subject: 'Nuevo Contacto', // Línea de asunto
          text: `Nombre: ${contactInfo.nombreCompleto}\nCorreo: ${contactInfo.correoElectronico}\nTeléfono: ${contactInfo.numeroTelefono}\nMensaje: ${contactInfo.mensaje}`, // cuerpo de texto plano
        };
  
        const emailResponse = await fetch(`${process.env.REACT_APP_API_URL}/sendmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mailOptions),
        });
  
        if (emailResponse.ok) {
          console.log('Correo enviado exitosamente');
        } else {
          console.error('Error al enviar el correo');
        }
      }
    } catch (error) {
      // console.error("Error al enviar el formulario:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contactInfo.privacyPolicy) {
      handleSubmitToServer();
    } else {
      alert("Por favor acepta las políticas de privacidad");
    }
  };

  return (
    <div
      className={` gap-4 transition-opacity duration-3000 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
      ref={ref}
    >
      <div className="bg-gray-100">
        <div className="w-4/5 mx-auto">
          <div className="flex flex-wrap ">
            <div className="w-full md:w-1/2">
              <img
                src={ContactImage}
                alt={intl.formatMessage({ id: "contactImage" })}
                className="w-3/4  object-cover"
              />
            </div>
            <div className="w-full md:w-1/2 p-8 flex flex-col justify-center">
              <h1 className="text-4xl font-bold mb-4 text-custom-blue">
                {intl.formatMessage({ id: "weAreHereToHelp" })}
              </h1>
              <h1 className="text-4xl font-bold mb-4 text-custom-blue">
                {intl.formatMessage({ id: "contactUs" })}
              </h1>
              <p className="mb-8 text-lg text-justify">
                {intl.formatMessage({ id: "contactFormInfo" })}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="fullname"
                  >
                    {intl.formatMessage({ id: "fullName" })}
                  </label>
                  <input
                    id="fullname"
                    type="text"
                    name="nombreCompleto"
                    onChange={handleChange}
                    value={contactInfo.nombreCompleto || ""}
                    placeholder={intl.formatMessage({
                      id: "fullNamePlaceholder",
                    })}
                    required
                    className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="correoElectronico"
                  >
                    {intl.formatMessage({ id: "email" })}
                  </label>
                  <input
                    id="correoElectronico"
                    type="email"
                    name="correoElectronico"
                    onChange={handleChange}
                    value={contactInfo.correoElectronico || ""}
                    placeholder={intl.formatMessage({ id: "emailPlaceholder" })}
                    required
                    className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="telefono"
                  >
                    {intl.formatMessage({ id: "phone" })}
                  </label>
                  <input
                    id="numeroTelefono"
                    type="tel"
                    name="numeroTelefono"
                    onChange={handleChange}
                    value={contactInfo.numeroTelefono || ""}
                    placeholder={intl.formatMessage({ id: "phonePlaceholder" })}
                    required
                    className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="mensaje"
                  >
                    {intl.formatMessage({ id: "message" })}
                  </label>
                  <textarea
                    name="mensaje"
                    id="mensaje"
                    onChange={handleChange}
                    value={contactInfo.mensaje || ""}
                    placeholder={intl.formatMessage({
                      id: "messagePlaceholder",
                    })}
                    required
                    className="shadow appearance-none border rounded w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-sm">
                    <input
                      id="privacyPolicy"
                      type="checkbox"
                      name="privacyPolicy"
                      checked={contactInfo.privacyPolicy}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    {intl.formatMessage({ id: "privacyPolicy" })}{" "}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      className="text-blue-500 hover:underline"
                    >
                      {intl.formatMessage({ id: "privacyPolicyLink" })}
                    </Link>
                  </label>
                </div>
                <div className="mb-4">
                  <button
                    type="submit"
                    className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110"
                  >
                    {intl.formatMessage({ id: "submit" })}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
