import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../assets/locales/eng.json';
import Spanish from '../assets/locales/esp.json';
import PhoneBar from "./PhoneBar.js";
import NavBar from "./NavBar.js";
import Banner from "./Banner.js";
// import ServiceBanner from "./ServiceBanner.js";
import AboutUs from "./AboutUs.js";
import VisionMission from "./VisionMission.js";
import SocialButtons from "./SocialButtons.js";
import QuoteNow from './QuoteNow.js';
// import Doorstep from "./Doorstep.js";
import Footer from "./Footer.js";
// import DoorService from "./DoorService.js";
import Why809 from "./Why809.js";
import ContactUs from "./ContactUs.js";
// import SocialMediaCTA from "./SocialMediaCTA.js";
import Tracking from "./Tracking.js";
import Carousel from './MyCarousel.jsx';

const Home = () => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'es');
  const [messages, setMessages] = useState(locale === 'en' ? English : Spanish);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (locale === 'en') {
      setMessages(English);
    } else {
      setMessages(Spanish);
    }
  }, [locale]);

  const changeLanguage = (lang) => {
    setLocale(lang);
    localStorage.setItem('locale', lang);
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div>
        <NavBar onLanguageChange={changeLanguage} />
        <PhoneBar />
        <Banner />
        {/* <ServiceBanner /> */}
        <div id="aboutus">
          <AboutUs />
        </div>
        <VisionMission />
        {/* <SocialMediaCTA /> */}
        <QuoteNow />
        <Carousel />
        {/* <DoorService /> */}
        <Why809 />
        <Tracking />
        <SocialButtons />
        <div id="contactus">
          <ContactUs />
        </div>
        <Footer />
      </div>
    </IntlProvider>
  );
};

export default Home;