import React from "react";
import { Link } from "react-router-dom";
import videoUrl from "../assets/video/809-intro2.mp4";
import LogoImage from "../assets/img/logo-menu.jpg";

const IntroPage = () => {
  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div
        className="absolute top-1/2 left-1/2 flex items-center justify-center w-full h-64 sm:h-96 z-10"
        style={{
          transform: "translate(-50%, -50%)",
          background:
            "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%)",
        }}
      >
        <Link to="/home" className="w-32 sm:w-48 lg:w-64">
          <img
            src={LogoImage}
            alt="809 Shipping"
            className="w-full h-auto max-w-md transform transition-transform duration-300 hover:scale-125"
          />
        </Link>
      </div>
    </div>
  );
};

export default IntroPage;
