import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useIntl } from "react-intl";
import ServiceBanner from "./ServiceBanner.js";
import ContacUs from "./ContactUs.js";
import bgImage from "../assets/img/logistic.jpg";
import cargaMaritima from "../assets/img/carga-maritima.png";
import cargaAerea from "../assets/img/carga-aerea.png";
import cargaTerrestre from "../assets/img/carga-terrestre.png";
import almacenaje from "../assets/img/almacenaje.png";
import puertaApuerta from "../assets/img/puerta-a-puerta.png";
import despachoAduanero from "../assets/img/despacho-aduanero.png";
import Footer from "./Footer.js";
import Navbar from "./NavBar.js";
import PhoneBar from "./PhoneBar.js";
import SocialButtons from "./SocialButtons.js";
import Carousel from "./MyCarousel.jsx";

const Services = ({ onLanguageChange }) => {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <Navbar onLanguageChange={onLanguageChange} />
      <PhoneBar />
      <SocialButtons />
      <div
        className="relative flex items-center justify-center h-screen bg-cover pt-36 md:pt-24"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="text-center space-y-4 z-10">
          <h1 className="text-4xl md:text-6xl font-bold text-white">
            {intl.formatMessage({ id: "services.banner" })}
          </h1>
          <p className="text-sm md:text-lg text-white whitespace-pre-wrap p-4 md:p-8">
            {intl.formatMessage({ id: "services.description1" })}
            <br />
            {intl.formatMessage({ id: "services.description2" })}
            <br />
            {intl.formatMessage({ id: "services.description3" })}
          </p>
          <HashLink smooth to="/quotation">
            <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
              {intl.formatMessage({ id: "services.button" })}
            </button>
          </HashLink>
        </div>
      </div>
      <ServiceBanner />
      <div className="w-4/5 mx-auto">
        <div
          id="cargaMaritima"
          className="flex flex-col md:flex-row items-center justify-center  space-x-0 md:space-x-4 p-16"
        >
          <div className="flex justify-center items-center w-full md:w-1/2">
            <img
              src={cargaMaritima}
              alt="Carga Maritima"
              className="object-cover"
            />
          </div>
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pl-0 md:pl-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.sea.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.sea.description1" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.sea.description2" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
        </div>
        <hr className="w-4/5 mx-auto" />
        <div
          id="cargaAerea"
          className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 p-16"
        >
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pr-0 md:pr-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.air.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.air.description" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
          <div className="flex justify-center items-center w-full md:w-1/2 pt-4 md:pt-8">
            <img src={cargaAerea} alt="Carga Aerea" className="object-cover" />
          </div>
        </div>
        <hr className="w-4/5 mx-auto" />
        <div
          id="transporteTerrestre"
          className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 p-16"
        >
          <div className="flex justify-center items-center w-full md:w-1/2">
            <img
              src={cargaTerrestre}
              alt="Transporte Terrestre"
              className="object-cover"
            />
          </div>
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pl-0 md:pl-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.land.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.land.description1" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.land.description2" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
        </div>
        <hr className="w-4/5 mx-auto" />
        <div
          id="almacenaje"
          className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 p-16"
        >
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pr-0 md:pr-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.storage.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.storage.description1" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.storage.description2" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.storage.description3" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
          <div
            className="flex justify-center items-center w-full md:w-1/2"
            style={{ paddingTop: "1rem !important" }}
          >
            <img src={almacenaje} alt="Almacenaje" className="object-cover" />
          </div>
        </div>
        <hr className="w-4/5 mx-auto" />
        <div
          id="puertaApuerta"
          className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 p-16"
        >
          <div className="flex justify-center items-center w-full md:w-1/2">
            <img
              src={puertaApuerta}
              alt="Puerta a Puerta"
              className="object-cover"
            />
          </div>
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pl-0 md:pl-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.doorToDoor.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.doorToDoor.description1" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.doorToDoor.description2" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.doorToDoor.description3" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
        </div>
        <hr className="w-4/5 mx-auto" />
        <div
          id="despachoAduanero"
          className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 p-16"
        >
          <div className="flex flex-col items-center space-y-4 w-full md:w-1/2 pr-0 md:pr-10">
            <h1 className="text-4xl font-bold text-custom-blue">
              {intl.formatMessage({ id: "services.customs.title" })}
            </h1>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.customs.description1" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.customs.description2" })}
            </p>
            <p className="text-lg py-4 text-justify">
              {intl.formatMessage({ id: "services.customs.description3" })}
            </p>
            <HashLink smooth to="/quotation">
              <button className="bg-custom-red hover:bg-custom-blue text-white font-bold py-2 px-4 rounded w-full sm:w-48 whitespace-nowrap transform transition-transform duration-500 hover:scale-110">
                {intl.formatMessage({ id: "services.button" })}
              </button>
            </HashLink>
          </div>
          <div className="flex justify-center items-center w-full md:w-1/2 md:py-10">
            <img
              src={despachoAduanero}
              alt="Daspacho Aduanero"
              className="object-cover"
            />
          </div>
        </div>
      </div>
      <Carousel />
      <ContacUs />
      <Footer />
    </div>
  );
};

export default Services;
