import React, { useState, useEffect } from "react";
import axios from "axios";
import pencilIcon from "../assets/icons/pencil.svg";

const UserDetail = ({ user, onClose }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });
  const [localUser, setLocalUser] = useState({ ...user });
  const [token, setToken] = useState(null);
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
    }
  }, []);

  if (!user) {
    return null;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };


  const handleInputChange = (event) => {
    const updatedUser = {
      ...editedUser,
      [event.target.name]: event.target.value,
    };
    setEditedUser(updatedUser);
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    console.log("token localStorage" + localStorage.getItem("token"));

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${editedUser.correoElectronico}`,
        editedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsEditing(false);
        setLocalUser(response.data);
        onClose();
      })
      .catch((error) => {
        console.error("Error updating user: ", error);
        console.log("Authorization", `Bearer ${token}`);
      });
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full md:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="flex justify-between items-center">
                  <h3
                    className="text-lg leading-6 font-medium text-custom-blue"
                    id="modal-title"
                  >
                    {isEditing ? (
                      <input
                        type="text"
                        name="nombreCompleto"
                        value={editedUser.nombreCompleto}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.nombreCompleto
                    )}
                  </h3>
                  {!isEditing && userRole === "admin" && (
                    <img
                      src={pencilIcon}
                      alt="Edit"
                      className="cursor-pointer"
                      onClick={handleEditClick}
                    />
                  )}
                </div>
                <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
                  <p className="text-sm text-gray-500 font-semibold">
                    Nombre Completo:
                  </p>
                  <p className="text-sm text-gray-500">
                    {isEditing ? (
                      <input
                        type="text"
                        name="nombreCompleto"
                        value={editedUser.nombreCompleto}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.nombreCompleto
                    )}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    Correo Electrónico:
                  </p>
                  <p className="text-sm text-gray-500">
                    {isEditing ? (
                      <input
                        type="text"
                        name="correoElectronico"
                        value={editedUser.correoElectronico}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.correoElectronico
                    )}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">
                    Contraseña:
                  </p>
                  <p className="text-sm text-gray-500">
                    {isEditing ? (
                      <input
                        type="password"
                        name="contrasena"
                        value={editedUser.contrasena}
                        onChange={handleInputChange}
                      />
                    ) : (
                      "*".repeat(10)
                    )}
                  </p>
                  <p className="text-sm text-gray-500 font-semibold">Rol:</p>
                  <p className="text-sm text-gray-500">
                    {isEditing ? (
                      <select
                        name="rol"
                        value={editedUser.rol}
                        onChange={handleInputChange}
                      >
                        <option value="admin">admin</option>
                        <option value="estandar">estandar</option>
                      </select>
                    ) : (
                      user.rol
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {localStorage.getItem("userRole") === "admin" && (
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-blue text-base font-medium text-white hover:bg-blue-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleSave}
              >
                Guardar
              </button>
            )}
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-red text-base font-medium text-white hover:bg-red-700  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
